import React, { useEffect, useCallback } from 'react'
import { useRecoilValue, useRecoilState } from 'recoil'
import * as dates from '@1f8/commons/date'

import * as atoms from 'lib/atoms'
import * as api from 'lib/apis/mock'
import AppComponent from 'components/app/App'
import Layout from 'components/Layout'
import useDashboardData from 'lib/hooks/useDashboardData'
import { MockShopifyLayout } from 'components/polaris'
import { LoadingSkeleton } from 'components/ui'
import { LoadingStatus } from 'lib/enum'
import { Store } from '@sh/models/classes'
import { Integration } from '@sh/enums'

// Testing
import * as prodapi from 'lib/apis/graphql'

const DemoPage = () => {
  const [loadingData, setLoadingData] = useRecoilState(atoms.loadingData)
  const [store, setStore] = useRecoilState(atoms.store)
  const settingsHistory = useRecoilValue(atoms.settingsHistory)

  const [dateRange, setDateRange] = useRecoilState(atoms.selectedDateRange)
  const [compareDateRange, setCompareDateRange] = useRecoilState(atoms.selectedCompareDateRange)

  // Data models
  const {
    resetCurrentDashboardData,
    resetComparisonDashboardData,
    updateShopifyTotal,
    updateShopifyChannels,
    updateAdTotal,
    updateAdCampaigns,
    updateGaTotal,
    updateGAChannels,
  } = useDashboardData()

  // STATE MANAGEMENT

  /** Initialize State / On Load */
  useEffect(() => {
    loadInitialStoreState()
      .then(() => {
        setInitialDateRanges()
      })

    // // testing calls locally
    // prodapi.yjp.getYJPCampaigns({ shop:'test', from:20230101, to:20230114, accountID:'1285410', totals:false })
    //   .then(y => {
    //     if (y) {
    //       console.log('YJP results', y)
    //       updateYJPCampaigns(y, true)
    //       // updateYJPTotal(y.data, isMainPeriod)
    //     }
    //   })

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /** Store state cant be safely updated via the Store object, store state changes happen via the state change history
   *    This might not be an ideal setup, so a refactor might be necessary
  */
  useEffect(() => {
    respondToStoreStateChanges()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settingsHistory])

  /** Respond to date range changes */
  useEffect(() => {
    loadDashboardData()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange])

  /** Respond to second date range changes */
  useEffect(() => {
    if (isValidDate(compareDateRange?.start)) {
      const dateInts = {
        start: dates.dateToInt(compareDateRange.start),
        end: dates.dateToInt(compareDateRange.end),
      }
      resetComparisonDashboardData()
      loadDataTotals(dateInts.start, dateInts.end, false)
        .then(() => {
          loadDataDetails(dateInts.start, dateInts.end, false)
        })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [compareDateRange])

  // METHODS

  const isValidDate = (dt) => (Object.prototype.toString.call(dt) === '[object Date]') ? isNaN(dt.getTime()) ? false : true : false

  const handleApiErr = (provider:Integration, e:Error) => {
    console.error('error occurred during fetching data', { provider, e })
  }

  const loadInitialStoreState = () => {
    return api.getStore()
      .then(storeItem => {
        setStore(new Store(storeItem))
      })
      .then(() => {
        setLoadingData(prev => ({
          ...prev,
          store: LoadingStatus.Completed,
        }))
      })
  }

  const respondToStoreStateChanges = useCallback(() => {
    if (!settingsHistory.length) return
    const updatedStore = settingsHistory[settingsHistory.length-1]?.store
    if (updatedStore) {
      api.updateStore(updatedStore)
        .then(updatedStoreItem => {
          setStore(new Store(updatedStoreItem))
          loadDashboardData()
        })
        .then(() => {
          setLoadingData(prev => ({
            ...prev,
            store: LoadingStatus.Completed,
          }))
        })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const setInitialDateRanges = () => {
    // Set as MTD
    const today = dates.dateToInt(new Date())
    const yesterday = dates.addDaysInt(today, -1)
    const yesterdayDt = dates.intToDate(yesterday)
    const thisYear = yesterdayDt.getFullYear()
    const thisMonth = yesterdayDt.getMonth() + 1
    const firstDayOfMonth = Number(`${yesterdayDt.getFullYear()}${thisMonth<=9?'0'+thisMonth:thisMonth}01`)
    const _dateRange = {
      start: dates.intToDate(firstDayOfMonth),
      end:   yesterdayDt,
    }
    setDateRange(_dateRange)

    // Set comparison date range as last MTD
    const lastMonth = thisMonth===1 ? 12 : (thisMonth-1)
    const lastMonthYear = lastMonth===12 ? (thisYear -1) : thisYear
    const yesterdayLastMonth = Number(`${lastMonthYear}${lastMonth}${_dateRange.end.getDate()<=9?'0'+_dateRange.end.getDate():_dateRange.end.getDate()}`)
    const firstDayLastMonth  = Number(`${lastMonthYear}${lastMonth}01`)
    const _dateRangeCompare = {
      start: dates.intToDate(firstDayLastMonth),
      end:   dates.intToDate(yesterdayLastMonth),
    }
    setCompareDateRange(_dateRangeCompare)

    console.log('demo initial date ranges set', {_dateRange, _dateRangeCompare})
  }

  const loadDataTotals = async(
    start: number,
    end:   number,
    isMainPeriod: boolean,
  ):Promise<void> => {
    const integrations = store?.integrations?.map(t => t.service) || []
    if (!integrations.includes(Integration.FACEBOOK)) updateAdTotal(Integration.FACEBOOK, null, isMainPeriod)
    if (!integrations.includes(Integration.GOOGLE))   updateAdTotal(Integration.GOOGLE, null, isMainPeriod)
    if (!integrations.includes(Integration.YJP))      updateAdTotal(Integration.YJP, null, isMainPeriod)
    if (!integrations.includes(Integration.GA))       updateGaTotal(null, isMainPeriod)

    if (!store) return

    const promises = [
      api.getShopifyData(start, end)
        .then(({total,channels}) => {
          updateShopifyTotal(total, isMainPeriod)
          updateShopifyChannels(channels, isMainPeriod)
        })
        .catch(e => handleApiErr(Integration.SHOPIFY, e)),
    ]
    console.log('demo store integrations to be loaded', store.integrations)
    store.integrations?.forEach(integration => {
      if (integration.service===Integration.FACEBOOK) {
        promises.push(
          api.getFbTotals(start, end)
            .then(d => {
              updateAdTotal(integration.service, d, isMainPeriod)
            })
            .catch(e => handleApiErr(integration.service, e)),
        )
      }
      if (integration.service===Integration.GOOGLE) {
        promises.push(
          api.getGoogleTotals(start, end)
            .then(d => {
              updateAdTotal(integration.service, d, isMainPeriod)
            })
            .catch(e => handleApiErr(integration.service, e)),
        )
      }
      if (integration.service===Integration.YJP) {
        promises.push(
          api.getYJPTotals(start, end)
            .then(d => {
              updateAdTotal(integration.service, d, isMainPeriod)
            })
            .catch(e => handleApiErr(integration.service, e)),
        )
      }
      if (integration.service===Integration.GA) {
        promises.push(
          api.getGADataTotals(start, end)
            .then(d => {
              updateGaTotal(d, isMainPeriod)
            })
            .catch(e => handleApiErr(integration.service, e)),
        )
      }
    })

    await Promise.all(promises)
  }

  const loadDataDetails = async(
    start: number,
    end:   number,
    isMainPeriod: boolean,
  ):Promise<void> => {
    const integrations = store?.integrations?.map(t => t.service) || []
    if (!integrations.includes(Integration.FACEBOOK)) updateAdCampaigns(Integration.FACEBOOK, null, isMainPeriod)
    if (!integrations.includes(Integration.GOOGLE))   updateAdCampaigns(Integration.GOOGLE, null, isMainPeriod)
    if (!integrations.includes(Integration.YJP))      updateAdCampaigns(Integration.YJP, null, isMainPeriod)
    if (!integrations.includes(Integration.GA))       updateGAChannels(null, isMainPeriod)

    if (!store?.integrations?.length) return

    const promises = []
    store.integrations.forEach(integration => {
      if (integration.service===Integration.FACEBOOK) {
        promises.push(
          api.getFbCampaigns(start, end)
            .then(d => {
              updateAdCampaigns(integration.service, d, isMainPeriod)
            })
            .catch(e => handleApiErr(integration.service, e)),
        )
      }
      if (integration.service===Integration.GOOGLE) {
        promises.push(
          api.getGoogleCampaigns(start, end)
            .then(d => {
              updateAdCampaigns(integration.service, d, isMainPeriod)
            })
            .catch(e => handleApiErr(integration.service, e)),
        )
      }
      if (integration.service===Integration.YJP) {
        promises.push(
          api.getYJPCampaigns(start, end)
            .then(d => {
              updateAdCampaigns(integration.service, d, isMainPeriod)
            })
            .catch(e => handleApiErr(integration.service, e)),
        )
      }
      if (integration.service===Integration.GA) {
        promises.push(
          api.getGAChannelData(start, end)
            .then(d => {
              updateGAChannels(d, isMainPeriod)
            })
            .catch(e => handleApiErr(integration.service, e)),
        )
      }
    })

    await Promise.all(promises)
  }

  const loadDashboardData = async() => {
    const dateInts = {
      start: dates.dateToInt(dateRange.start),
      end: dates.dateToInt(dateRange.end),
    }
    resetCurrentDashboardData()
    loadDataTotals(dateInts.start, dateInts.end, true)
      .then(() => {
        loadDataDetails(dateInts.start, dateInts.end, true)
      })
  }

  return (
    <Layout>
      <MockShopifyLayout>
        <LoadingSkeleton loading={loadingData.store!==LoadingStatus.Completed}>
          <AppComponent
            demoMode={true}
          ></AppComponent>
        </LoadingSkeleton>
      </MockShopifyLayout>
    </Layout>
  )
}

export default DemoPage
