import { Integration, ShopStatus } from '@sh/enums'
import { StoreItem } from '@sh/models/classes/Store'

let _mockStore:StoreItem = {
  id: 'test',
  created: 1,
  name: 'demo',
  origin: 'https://demo.myshopify.com',
  host: 'demo.myshopify.com',
  status: ShopStatus.CREATED,
  emails: [],
  events: [],
  url: 'https://demo.myshopify.com',
  updated: 1,
  integrations: [
   {
      accountID: 'G12345',
      account: 'Demo Google Ads Account',
      service: Integration.GOOGLE,
      username: 'demo',
      active: true,
   },
   {
      accountID: 'UA-12345',
      account: 'Demo GA Account',
      service: Integration.GA,
      username: 'demo',
      active: true,
   },
   {
      accountID: 'FB123',
      account: 'Demo Facebook Account',
      service: Integration.FACEBOOK,
      username: 'demo',
      active: true,
   },
   // {
   //    accountID: 'YJP123',
   //    account: 'Demo YJP Account',
   //    service: Integration.YJP,
   //    username: 'demo',
   //    active: true,
   // },
  ],
}

export const getMockStore = () => _mockStore

export const updateMockStore = (item:StoreItem) => {
   _mockStore = item
}
