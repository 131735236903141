import { sleep } from '@sh/lib/etc'
import { Store, StoreItem } from '@sh/models/classes/Store'
import { AdData, DataProvider, EComData, GAData } from '@sh/models/classes'
import { AdAccount } from '@sh/models/interfaces'
import { Integration } from '@sh/enums'

// import { Order } from '@sh/models/classes/Order'
// import shData from './shData'
// import fbData from './fbData'
// import gData from './gData'
// import gaData from './gaData'
import { getMockStore, updateMockStore } from './mockStore'

const API_LATENCY = {
  store: 1000,
  account: 1000,
  accounts: 1500,
  shopify: 5000,
  facebook: 10000,
  google: 3000,
  ga: 1500,
  yjp: 30000,
}

export const getStore = async():Promise<StoreItem> => {
  await sleep(API_LATENCY.shopify)
  return getMockStore()
}

export const updateStore = async(store:Store):Promise<StoreItem> => {
  await sleep(API_LATENCY.shopify)
  const mock = store.getItem()
  // console.log('update new store item', store.integrations, mock.integrations)

  await updateMockStore(mock)
  return mock
}

export const getOneAdAccount = async (account, optionSelected) => {
  await sleep(API_LATENCY.account)
  return ({
    service: Integration[optionSelected],
    account: account?.name,
    username: 'demo',
    accountID: account?.id,
    active: Math.random() < .9 ? true : false,
  })
}

export const getDataProviderAccounts = async(provider:DataProvider):Promise<AdAccount[]> => {
  await sleep(API_LATENCY.accounts)

  const accounts:AdAccount[] = []
  for (let i=0; i<20; i++) {
    accounts.push({
      id: Math.ceil(Math.random()*10000000).toString(),
      name: `${provider.service} Account ${i}`,
      status: Math.random() < .9 ? 'ACTIVE' : 'CLOSED',
    })
  }
  return accounts
}

/** Generate Random Ads/GA/ECom Data */

export const getShopifyData = async(
  from:number,
  to:number,
):Promise<{
  total: EComData,
  channels: EComData[],
}> => {
  await sleep(API_LATENCY.shopify)
  const total = generateEComData(from, to, '')
  const mockShopifyChannels = {
    'Online Store': .7,
    'Google': .1,
    'Facebook': .1,
    'Yahoo': .1,
  }
  const channels = Object.entries(mockShopifyChannels)
    .map(([channel, ratio]) => {
      const ec = new EComData(from, to, channel)
      ec.orders.new = Math.floor(total.orders.new*ratio)
      ec.orders.returning = Math.floor(total.orders.returning*ratio)
      ec.revenue.new = Math.floor(total.revenue.new*ratio)
      ec.revenue.returning = Math.floor(total.revenue.returning*ratio)
      ec.refunds.new = Math.floor(total.refunds.new*ratio)
      ec.refunds.returning = Math.floor(total.refunds.returning*ratio)
      ec.customers.new = Math.floor(total.customers.new*ratio)
      ec.customers.returning = Math.floor(total.customers.returning*ratio)
      return ec
    })
  return {
    total,
    channels,
  }
}

export const getFbCampaigns = async(from:number, to:number):Promise<AdData[]> => {
  await sleep(API_LATENCY.facebook)
  const data:AdData[] = []

  const campaigns = ['FB Main', 'FB Prospect', 'FB Remarket', 'FB Misc Products']
  for (const campaign of campaigns) {
    const campaignData = generateAdData(Integration.YJP, from, to, campaign)
    data.push(campaignData)
  }

  return data
}
export const getFbTotals = async(from:number, to:number):Promise<AdData> => {
  await sleep(API_LATENCY.facebook)

  const campaigns = ['FB Main', 'FB Prospect', 'FB Remarket', 'FB Misc Products']
  const totals = new AdData(Integration.FACEBOOK, from, to)
  for (const campaign of campaigns) {
    const campaignData = generateAdData(Integration.YJP, from, to, campaign)
    totals.addStats(campaignData.impressions, campaignData.clicks)
    totals.addSpend(campaignData.spend)
    totals.addConversions(campaignData.conversions, campaignData.conversionValue)
  }

  return totals
}

export const getGoogleCampaigns = async(from:number, to:number):Promise<AdData[]> => {
  await sleep(API_LATENCY.google)
  const data:AdData[] = []

  const campaigns = ['G.Ads Main', 'G.Ads Prospect', 'G.Ads Remarket', 'G.Ads Misc Products']
  for (const campaign of campaigns) {
    const campaignData = generateAdData(Integration.YJP, from, to, campaign)
    data.push(campaignData)
  }

  return data
}
export const getGoogleTotals = async(from:number, to:number):Promise<AdData> => {
  await sleep(API_LATENCY.google)

  const campaigns = ['G.Ads Main', 'G.Ads Prospect', 'G.Ads Remarket', 'G.Ads Misc Products']
  const totals = new AdData(Integration.GOOGLE, from, to)
  for (const campaign of campaigns) {
    const campaignData = generateAdData(Integration.YJP, from, to, campaign)
    totals.addStats(campaignData.impressions, campaignData.clicks)
    totals.addSpend(campaignData.spend)
    totals.addConversions(campaignData.conversions, campaignData.conversionValue)
  }

  return totals
}

export const getYJPCampaigns = async(from:number, to:number):Promise<AdData[]> => {
  await sleep(API_LATENCY.yjp)
  const data:AdData[] = []

  const campaigns = ['Yahoo Main', 'Yahoo Prospect', 'Yahoo Remarket', 'Yahoo Misc Products']
  for (const campaign of campaigns) {
    const campaignData = generateAdData(Integration.YJP, from, to, campaign)
    data.push(campaignData)
  }

  return data
}
export const getYJPTotals = async(from:number, to:number):Promise<AdData> => {
  await sleep(API_LATENCY.yjp)

  const campaigns = ['Yahoo Main', 'Yahoo Prospect', 'Yahoo Remarket', 'Yahoo Misc Products']
  const totals = new AdData(Integration.YJP, from, to)
  for (const campaign of campaigns) {
    const campaignData = generateAdData(Integration.YJP, from, to, campaign)
    totals.addStats(campaignData.impressions, campaignData.clicks)
    totals.addSpend(campaignData.spend)
    totals.addConversions(campaignData.conversions, campaignData.conversionValue)
  }

  return totals
}

export const getGAChannelData = async(from:number, to:number):Promise<GAData[]> => {
  await sleep(API_LATENCY.ga)
  const data:GAData[] = []

  const channels = ['Social','Search','Organic','Email','Referral','Other']
  for (const channel of channels) {
    const channelData = generateGAData(from, to, channel)
    data.push(channelData)
  }

  return data
}
export const getGADataTotals = async(from:number, to:number):Promise<GAData> => {
  await sleep(API_LATENCY.ga)

  const channels = ['Social','Search','Organic','Email','Referral','Other']
  const totals = new GAData(from, to, 'ALL')
  for (const channel of channels) {
    const channelData = generateGAData(from, to, channel)
    totals.addSessionData(channelData.sessions, channelData.engagedSessions)
    totals.addTransactionData(channelData.transactions, channelData.revenue)
  }

  return totals
}

/** Random Data Generators */

const AOV =  Math.random()*50 + 100

// this is to ensure data generated between cache runs is consistent
const RANDOM_DATA_CACHE:Record<string, any> = {}

export const generateAdData = (provider:Integration, from:number, to:number, desc:string):AdData => {
  const key = [provider,from,to,desc].join('|')
  if (RANDOM_DATA_CACHE[key]) return RANDOM_DATA_CACHE[key]

  const data = new AdData(provider, from, to, desc)
  for (let at=from; at<=to; at++) {
    const impressions = Math.floor(Math.random()*25040 + 10000)
    const ctr = ((Math.random()-.5) + 1)/100
    const clicks = Math.floor(impressions*ctr)
    const cpc = Math.random()*200 + 100
    const spend = clicks*cpc
    const crate = (Math.random()*3 + 1)/100
    const conversions = Math.floor(clicks*crate)
    const conversionValue = conversions*AOV
    data.addStats(impressions, clicks)
    data.addSpend(spend)
    data.addConversions(conversions, conversionValue)
  }

  RANDOM_DATA_CACHE[key] = data
  return data
}

export const generateGAData = (from:number, to:number, desc:string):GAData => {
  const key = ['GA',from,to,desc].join('|')
  if (RANDOM_DATA_CACHE[key]) return RANDOM_DATA_CACHE[key]

  const data = new GAData(from, to, desc)
  for (let at=from; at<=to; at++) {
    const sessions = Math.floor(Math.random()*150400 + 50000)
    const engagementRate = (Math.random()*10 + 40)/100
    const engagedSessions = Math.floor(engagementRate*sessions)
    const crate = (Math.random()*4 + 1)/100
    const transactions = Math.floor(crate*engagedSessions)
    const revenue = transactions*AOV
    data.addSessionData(sessions, engagedSessions)
    data.addTransactionData(transactions, revenue)
  }

  RANDOM_DATA_CACHE[key] = data
  return data
}

export const generateEComData = (from:number, to:number, desc:string):EComData => {
  const key = ['SHOPIFY',from,to,desc].join('|')
  if (RANDOM_DATA_CACHE[key]) return RANDOM_DATA_CACHE[key]

  const data = new EComData(from, to, desc)
  for (let at=from; at<=to; at++) {
    const newRatio = Math.random()/10 + .3
    const newOrders = Math.floor(Math.random()*5000 + 10000)
    const returningOrders = Math.floor(newOrders/newRatio - newOrders)
    const newRev = newOrders*(AOV-10)
    const returningRev = returningOrders*(AOV+5)
    const newCustomers = newOrders
    const returningCustomers = Math.floor(returningOrders*.9)
    data.orders.new += newOrders
    data.orders.returning += returningOrders
    data.revenue.new += newRev
    data.revenue.returning += returningRev
    data.refunds.new += (data.revenue.new*.05)
    data.refunds.returning += (data.revenue.new*.02)
    data.customers.new += newCustomers
    data.customers.returning += returningCustomers
  }

  RANDOM_DATA_CACHE[key] = data
  return data
}

/** STATIC DATA Version */

// const randomizeNumber = ():string => {
//   const r = Math.random()
//   return r >= .2 ? Math.ceil(r*10000000).toString() : null
// }

// const strToInt = (s:string) => Number(s.substring(0,10).replace(/-/g, ''))

// /** TODO pull real first order ID from API when querying orders */
// export const getShopifyOrders = async(from:number, to:number):Promise<Order[]> => {
//   await sleep(API_LATENCY.shopify)
//   return shData
//     .filter(sh => strToInt(sh.at)>=from && strToInt(sh.at)<=to)
//     .map(sh => new Order({
//       id: sh.id.toString(),
//       customer: sh.customer,
//       firstOrderID: randomizeNumber(),
//       at: strToInt(sh.at),
//       total: {
//         discount: sh.totalDiscountsSet,
//         shipping: sh.totalShippingPriceSet,
//         tax: sh.totalTaxSet,
//         subtotal: sh.subtotalPriceSet,
//         refunds: sh.totalRefundedSet,
//         total: sh.totalPriceSet,
//       },
//       lineItems: sh.lineItems,
//     }))
// }

// export const getFbCampaigns = async(from:number, to:number):Promise<AdData[]> => {
//   await sleep(API_LATENCY.facebook)
//   return fbData
//     .filter(f => f.at>=from && f.at<=to)
//     .map(f => new AdData({
//       at: f.at,
//       campaign: f.name,
//       impressions: f.impressions,
//       clicks: f.clicks,
//       spend:  f.spend,
//       conversions: f.purchases,
//       revenue: f.revenue,
//     }))
// }

// export const getGoogleCampaigns = async(from:number, to:number):Promise<AdData[]> => {
//   await sleep(API_LATENCY.google)
//   return gData
//     .filter(g => g.at>=from && g.at<=to)
//     .map(g => new AdData({
//       at: g.at,
//       campaign: g.name,
//       impressions: g.impressions,
//       clicks: g.clicks,
//       spend:  g.spend,
//       conversions: g.purchases,
//       revenue: g.revenue,
//     }))
// }

// export const getYJPCampaigns = async(from:number, to:number):Promise<AdData[]> => {
//   await sleep(API_LATENCY.yjp)
//   return []
// }

// export const getGAChannelData = async(from:number, to:number):Promise<GAChannelData[]> => {
//   await sleep(API_LATENCY.ga)
//   return gaData
//     .filter(g => g.at>=from && g.at<=to)
//     .map(g => new GAChannelData({
//       at: g.at,
//       channel: g.channel,
//       users: g.users,
//       newUsers: g.newUsers,
//       sessions: g.sessions,
//       sessionDuration: g.sessionDuration,
//       pageviews: g.pageviews,
//       bounces: g.bounces,
//       transactions: g.transactions,
//       revenue: g.revenue,
//       addToCarts: g.addToCarts,
//       checkouts: g.checkouts,
//     }))
// }
