import React from 'react'
import { Frame, Icon, Navigation, TopBar } from '@shopify/polaris'
import { MobileMajor, TabletMajor, DesktopMajor } from '@shopify/polaris-icons'

/** Shows space that would normally be occupied by Shopify's UI in an embedded app */
const EmptyFrameComponent = ({ children, location='/', title='1F8', subtitle='APP', initials='1' }) => {
  return (
    <Frame
      topBar={<TopBar
        searchField={<div className='flex flex-row'>
          <span className='mr-4'>Shopify UI Top Bar (reserved app space)</span>
          <span></span>
          <span className='sm:hidden md:hidden'>                 <span className='inline-block'><Icon source={MobileMajor} /></span> XSmall</span>
          <span className='hidden sm:block md:hidden'>           <span className='inline-block'><Icon source={MobileMajor} /></span> Small</span>
          <span className='hidden sm:hidden md:block lg:hidden'> <span className='inline-block'><Icon source={TabletMajor} /></span> Medium</span>
          <span className='hidden md:hidden lg:block xl:hidden'> <span className='inline-block'><Icon source={DesktopMajor} /></span> Large</span>
          <span className='hidden lg:hidden xl:block'>           <span className='inline-block'><Icon source={DesktopMajor} /></span> XLarge</span>
        </div>}
        userMenu={
          <TopBar.UserMenu
            actions={[]}
            name={title}
            detail={subtitle}
            initials={initials}
            open={false}
            onToggle={() => {}}
          />
        }
      />}
      navigation={<Navigation location={location}>
        <Navigation.Section
          title='Shopify UI Sidebar'
          items={[]}
        />
        <Navigation.Section
          separator
          title='This space is reserved in actual Shopify app'
          items={[]}
        />
      </Navigation>}
    >
      {children}
    </Frame>
  )
}

export default EmptyFrameComponent
